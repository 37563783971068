import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text';

import Layout from '../components/layout';
import SEO from '../components/seo';

import ArrowButton from '../components/arrow-button';
import Breadcrumbs from '../components/breadcrumbs';
import Card from '../components/card';
import Section from '../components/section';

interface Service {
  id: string;
  name: string;
  serviceId: string;
  subtitle: RenderRichTextData<any>;
  shortDescription: RenderRichTextData<any>;
  featuredImage: {
    description: string;
    localFile: {
      childImageSharp: {
        card: IGatsbyImageData;
        hero: IGatsbyImageData;
      };
    };
  };
}

interface DataProps {
  companyInfo: {
    entity: {
      companyName: string;
    };
  };
  services: {
    edges: {
      service: Service;
    }[];
  };
}

const ServicesPage: React.FC<PageProps<DataProps>> = (props) => {
  const {
    data: { companyInfo, services },
    location,
  } = props;

  const intl = useIntl();

  return (
    <>
      <SEO
        title={intl.formatMessage({
          id: 'Services',
          defaultMessage: 'Services',
        })}
      />

      <Layout
        breadcrumbs={
          <Breadcrumbs
            items={[
              { to: '/', title: intl.formatMessage({ id: 'Start', defaultMessage: 'Start' }) },
              {
                to: '/services',
                title: intl.formatMessage({ id: 'Services', defaultMessage: 'Services' }),
              },
            ]}
          />
        }
        path={location.pathname}
        title={
          <span>
            <FormattedMessage
              id="Services around places"
              defaultMessage="Services around places"
            />{' '}
            <span className="text-primary">
              <FormattedMessage id="you care about" defaultMessage="you care about" />
            </span>
          </span>
        }
      >
        <Section
          id="services"
          title={intl.formatMessage({ id: 'Services', defaultMessage: 'Services' })}
          subTitle={intl.formatMessage(
            {
              id:
                '{entity} creates services centered around places, whether that be bus stops, events or scavenger hunts. We package these in a way that is easy to use and understand. Simplified for you.',
              defaultMessage:
                '{entity} creates services centered around places, whether that be bus stops, events or scavenger hunts. We package these in a way that is easy to use and understand. Simplified for you.',
            },
            {
              entity: companyInfo.entity.companyName,
            }
          )}
        >
          <div className="mx-auto mt-4 leading-relaxed px-4 md:px-8 grid gap-8 grid-col-1 md:grid-cols-2 lg:grid-cols-3">
            {services.edges.map(({ service }) => (
              <Card
                key={service.id}
                title={service.name}
                image={
                  <GatsbyImage
                    alt={service.featuredImage.description}
                    image={service.featuredImage.localFile.childImageSharp.card}
                    draggable={false}
                    className="w-full"
                  />
                }
                footer={
                  <div className="text-right">
                    <ArrowButton
                      href={`/services/${service.serviceId}`}
                      small={true}
                      type="internal"
                      variant="tertiary"
                    >
                      <FormattedMessage
                        id="Learn more about {topic}"
                        defaultMessage="Learn more about {topic}"
                        values={{
                          topic: service.name,
                        }}
                      />
                    </ArrowButton>
                  </div>
                }
              >
                {renderRichText(service.subtitle)}
              </Card>
            ))}
          </div>
        </Section>
      </Layout>
    </>
  );
};

export default ServicesPage;

export const query = graphql`
  query ServicesPage($locale: String) {
    companyInfo {
      entity {
        companyName
      }
    }
    services: allContentfulService(filter: { node_locale: { eq: $locale } }) {
      edges {
        service: node {
          id
          name
          serviceId
          subtitle {
            raw
          }
          shortDescription {
            raw
          }
          featuredImage {
            description
            localFile {
              childImageSharp {
                card: gatsbyImageData(width: 500, height: 400, placeholder: BLURRED)
                hero: gatsbyImageData(width: 1920, height: 1000, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;
